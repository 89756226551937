<template>
  <div class="xiaoxi">
    <ul>
      <li
        :class="{ classification: classification == index }"
        @click="Jump(index)"
        v-for="(item,index) in type"
        :key="index"
      >{{item}}</li>
    </ul>
    <div class="router">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classification: 0, ///选中状态
      type: ["知识头条", "智能问答", "资源交易", "知识加工"]
    };
  },
  created() {
    if (this.$route.path == "/service") {
      //智能问答
      this.classification = 0;
    }
    if (this.$route.path == "/intelligence") {
      //资源交易
      this.classification = 1;
    }
    if (this.$route.path == "/resources") {
      //知识服务
      this.classification = 2;
    }
    if (this.$route.path == "/machining") {
      //知识加工
      this.classification = 3;
    }
  },
  methods: {
    Jump(index) {
      console.log("this.classification", this.classification, "index", index);
      if (this.classification != index) {
        if (index == 0) {
          this.$router.push("/service");
        } else if (index == 1) {
          this.$router.push("/intelligence");
        } else if (index == 2) {
          this.$router.push("/resources");
        } else if (index == 3) {
          this.$router.push("/machining");
        }
      }
      this.classification = index;
    }
  }
};
</script>
<style scoped>
.xiaoxi {
  width: 80%;
  display: inline-block;
  background-color: #ffffff;
  margin-left: 10%;
  margin-right: 10%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

  /* margin: auto; */
}
ul {
  width: 96.3%;
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 20px;
  /* border-bottom: 1px solid red; */
}
ul li {
  border: 1px solid #c2c3c5;
  border-radius: 4px;
  width: 118px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  /* float: left; */
  display: inline-block;
  cursor: pointer;
  margin-left: 6%;
  margin-right: 6%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #7d7d7d;
}
.classification {
  /* 选中状态样式 */
  background: #0084ff;
  color: #ffffff;
}
.router {
  border-top: 1px solid #dfdfdf;
  width: 90%;
  padding: 3% 3% 3% 3%;
}
</style>
